<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  id="sidebar1"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/coreui-pro-angular-white.svg',
      width: 190,
      height: 35,
      alt: 'CoreUI Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/coreui-signet-white.svg',
      width: 46,
      height: 46,
      alt: 'CoreUI Logo'
    }"
    [routerLink]="[]"
  >
  </c-sidebar-brand>

  <c-sidebar-nav [navItems]="navItems"></c-sidebar-nav>

  <c-sidebar-toggler
    *ngIf="!sidebar1.narrow"
    cSidebarToggle="sidebar1"
    toggle="unfoldable"
  ></c-sidebar-toggler>
</c-sidebar>

<!--aside-->
<app-secure-aside style="display: none;"></app-secure-aside>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
  <!--app-header-->
  <app-secure-header class="mb-2 d-print-none header " position="fixed"
                      sidebarId="sidebar1"></app-secure-header>
  <!--app-body-->
  <div class="body flex-grow-1">
    <c-container breakpoint="lg" class="h-auto">
      <router-outlet></router-outlet>
    </c-container>
  </div>
  <!--app footer-->
  <app-secure-footer></app-secure-footer>
</div>
