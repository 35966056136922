import { createAction, props } from '@ngrx/store';
import { RunSegment } from '../models/schedule.model';

// Update Run Segment
export const updateRunSegment = createAction('[Schedule] Update Run Segment', props<{ organizationId: string, weeklyScheduleId: number, runSegment: RunSegment }>());
export const updateRunSegmentSuccess = createAction('[Schedule] Update Run Segment Success', props<{ runSegment: RunSegment }>());

// Create Run Segment
export const createRunSegment = createAction('[Schedule] Create Run Segment', props<{ organizationId: string, weeklyScheduleId: number, runSegment: RunSegment }>());
export const createRunSegmentSuccess = createAction('[Schedule] Create Run Segment Success', props<{ runSegment: RunSegment }>());

// Delete Run Segment
export const deleteRunSegment = createAction('[Schedule] Delete Run Segment', props<{ organizationId: string, weeklyScheduleId: number,scheduledRunId: number, id: number }>());
export const deleteRunSegmentSuccess = createAction('[Schedule] Delete Run Segment Success', props<{ scheduledRunId: number, id: number }>());

