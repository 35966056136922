// client.effects.ts
import { Injectable } from '@angular/core';
import { Actions} from '@ngrx/effects';

@Injectable()
export class ClientEffects {
  constructor(private actions$: Actions) {}

  // You can add effects related to clients here if needed
}
