import { ActionCreator, createAction, props } from '@ngrx/store';
import { MessageType, NotificationMessage } from '../models/model';

// Loading components. NOT USED and needs to be evaluated if we will use this
export const startLoading = createAction('[UI] Start Loading', props<{ action: string }>());
export const endLoading = createAction('[UI] End Loading', props<{ action: string }>());

// add notification message
export const addNotificationMessage = createAction('[UI] Add Notification Message', props<{ message: string, messageType: MessageType }>());

// common error notification in component ts
export const serviceCallFailure = createAction('[UI] Service Failure', props<{action: any, error: any }>());
