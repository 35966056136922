import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CurrentUser } from './model';

@Injectable({ providedIn: 'root' })
export class AuthService {

    constructor() {

    }

    public async getCurrentUser(): Promise<CurrentUser | undefined> {
        try {
            const session = await Auth.currentSession();
            const accessToken = session && session.getAccessToken();
            const payload: any = accessToken && accessToken.payload;
            if (payload) {
                let roles = payload['cognito:groups'];
                if (roles) {
                    roles = roles.map((v: string) => v.toLowerCase());
                }

                return new CurrentUser(payload.username, roles);
            }
            return;
        }
        catch (error) {
            console.log(error)
            return;
        }
    }

    public async getAllowedClients(): Promise<string[]> {
        try {
            const session = await Auth.currentSession();
            const accessToken = session && session.getIdToken();
            const payload: any = accessToken && accessToken.payload;
            // alert (JSON.stringify(payload))
            if (payload) {
                let clientIds = payload['custom:client_ids'];
                if (clientIds) {
                    clientIds = clientIds.split(',').map((v: string) => v.toLowerCase());
                }
                return clientIds;
            }
            return [];
        }
        catch (error) {
            console.log(error)
            return [];
        }
    }
    
    public async getEmployeeId(): Promise<string[]> {
        try {
            const session = await Auth.currentSession();
            const accessToken = session && session.getIdToken();
            const payload: any = accessToken && accessToken.payload;
            // alert (JSON.stringify(payload))
            if (payload) {
                let employeeId = payload['custom:employee_id'];
                return employeeId;
            }
            return [];
        }
        catch (error) {
            console.log(error)
            return [];
        }
    }
    public async getOrganizationId(): Promise<string> {
        try {
            const session = await Auth.currentSession();
            const accessToken = session && session.getIdToken();
            const payload: any = accessToken && accessToken.payload;
            // alert (JSON.stringify(payload))
            if (payload) {
                let organizationId = payload['custom:organization_id'];
                return organizationId;
            }
            return '';
        }
        catch (error) {
            console.log(error)
            throw Error('No organization was loaded')
        }
    }

    public async getJwtToken(): Promise<string> {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    public async checkAccess(roles: string[]) {
        const user = await this.getCurrentUser();
        return this.checkUserAccess(user, roles);
    }

    public checkUserAccess(user: CurrentUser | undefined, roles: string[]) {
        if (!roles || roles.length == 0 || roles.includes('*')) {
            return true;
        }
        if (!user) { //not logged in
            return false;
        }
        const hasAccess = roles.filter(value => user?.roles?.includes(value)).length > 0;
        return hasAccess;
    }
}