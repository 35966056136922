import { createAction, props } from '@ngrx/store';
import { PayrateConfigs } from '../models/employee-payment-profile.model';
import { PayrollProcessorEmployee } from '../models/paychex.model';

// Load Payrate Configs
export const loadPayrateConfigurations = createAction('[Payment->EmployeePaymentProfile] Load Payrate Configurations', props<{ organizationId: string, clientId: string }>());
export const loadPayrateConfigurationsSuccess = createAction('[Payment->EmployeePaymentProfile] Load Payrate Configurations Success', props<{ payrateConfigs: PayrateConfigs }>());

// Load Paychex workers
export const loadPaychexWorkers = createAction('[Payment->EmployeePaymentProfile] Load Paychex Workers', props<{ organizationId: string, clientId: string }>());
export const loadPaychexWorkersSuccess = createAction('[Payment->EmployeePaymentProfile] Load Load Paychex Workers Success', props<{ clientId: string, paychexWorkers: PayrollProcessorEmployee[] }>());
