import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as PayableHourActions from '../actions/payable-hours.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PayrollPayableItemsService } from '../services/payroll-payable-items.service';
import { Payroll, EmployeePayableHour } from '../models/payroll.model';

@Injectable()
export class PayableHourEffects {
    constructor(private actions$: Actions, private payableItemsService: PayrollPayableItemsService, private store: Store<AppState>) { }

   
    loadPayableHours$ = createEffect(() => this.actions$.pipe(
        ofType(PayableHourActions.loadPayableHours),
        mergeMap(({ organizationId, clientId, payrollId, weekNumber, isApproved }) => this.payableItemsService.getEmployeePayableHours(organizationId, clientId, payrollId, weekNumber, isApproved).pipe(
            map((payableHours: EmployeePayableHour[]) => {
                return PayableHourActions.loadPayableHoursSuccess({ payableHours });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );
}
