export class NotificationMessage{
    message!: string;
    messageType!: MessageType
}

export enum MessageType {
    Success = "success",
    Warning = "warning",
    Error = "danger"
  }

  export type FeatureFlags = {
    paychexIntegration?: boolean
  }

  export type ClientFeatureFlags = {
    clientId: string;
    featureFlags: FeatureFlags
  }

  export type DownloadData = {
    body: any[][],
    head: any[],
    foot?: any[]
  }
