/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:54e83948-ae39-47c1-aea8-0d57f8f45b16",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_0988lfoXe",
    "aws_user_pools_web_client_id": "6fout30mj9n3ftk44dtkpbqe3u",
    "oauth": {
        "domain": "dev-smarter-trucking.auth.us-east-2.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "smartertruckingwebap-20231201164806-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3pks21ci9ak1l.cloudfront.net"
};


export default awsmobile;
