
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SchedulingService } from '../services/schedule.service';
import * as ScheduledRunActions from '../actions/scheduled-run.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { ScheduledRun } from '../models/schedule.model';

@Injectable()
export class ScheduledRunEffects {
    constructor(private actions$: Actions, private schedulingService: SchedulingService, private store: Store<AppState>) { }

    loadScheduledRuns$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledRunActions.loadScheduledRuns),
        mergeMap(({ organizationId, clientId, weeklyScheduleId }) => this.schedulingService.getScheduledRuns(organizationId, weeklyScheduleId).pipe(
            map((scheduledRuns: ScheduledRun[]) => {
                // console.log(scheduledRuns);
                return ScheduledRunActions.loadScheduledRunsSuccess({ scheduledRuns });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    updateScheduledRun$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledRunActions.updateScheduledRun),
        mergeMap(({organizationId, scheduledRun }) => this.schedulingService.updateScheduledRun(organizationId, scheduledRun).pipe(
            map((returnedScheduledRun) => {
                return ScheduledRunActions.updateScheduledRunSuccess({ scheduledRun });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    createScheduledRun$ = createEffect(() => this.actions$.pipe(
        ofType(ScheduledRunActions.createScheduledRun),
        mergeMap(({organizationId, scheduledRun }) => this.schedulingService.createScheduledRun(organizationId, scheduledRun).pipe(
            map((sr) => {
                // console.log(ScheduledRun);
                return ScheduledRunActions.createScheduledRunSuccess({ scheduledRun: sr });
            }),
            catchError(error => of( /* handle error action */))
        ))
    ));

}
