import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../authentication/authentication.service';

import { IExtendedNavData, navItems } from './_nav';

@Component({
  selector: 'app-secure-layout',
  templateUrl: './secure-layout.component.html'
})
export class SecureLayoutComponent implements OnInit{

  constructor(private _authService:AuthService) {}

  ngOnInit() {
    this.loadMenuItems(navItems);
  }

  public navItems: IExtendedNavData[]|undefined;


  async loadMenuItems(navItems: IExtendedNavData[]){
    const currentUser = await this._authService.getCurrentUser();
    this.navItems = navItems.filter((navItem: IExtendedNavData)=>{
        return !navItem.roles || this._authService.checkUserAccess(currentUser, navItem.roles);
    });
  }
}
