import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { EmployeeService } from '../services/employee.service';
import * as EmployeeActions from '../actions/employee.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable()
export class EmployeeEffects {
    constructor(private actions$: Actions, private employeeService: EmployeeService, private store: Store<AppState>) { }

    loadEmployees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeActions.loadEmployees),
            mergeMap(({ organizationId, showDeletedEmployees }) =>
                this.employeeService.getEmployees({ organizationId, showDeletedEmployees }).pipe(
                    map(employees => EmployeeActions.loadEmployeesSuccess({ employees })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    loadEmployeeById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeActions.loadEmployeeById),
            mergeMap(({ organizationId, employeeId }) =>
                this.employeeService.getEmployee(organizationId, employeeId).pipe(
                    map(selectedEmployee => EmployeeActions.loadEmployeeByIdSuccess({ selectedEmployee })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    loadEmployeeFullProfileById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeActions.loadEmployeeFullProfileByID),
            mergeMap(({ organizationId, employeeId }) =>
                this.employeeService.getEmployeeFullprofile(organizationId, employeeId).pipe(
                    map(selectedEmployee => EmployeeActions.loadEmployeeFullProfileByIDSuccess({ selectedEmployee })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    loadEmployeesFullProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeActions.loadEmployeesFullProfile),
            mergeMap(({ organizationId }) =>
                this.employeeService.getEmployeesFullprofile(organizationId).pipe(
                    map(fullProfileEmployees => EmployeeActions.loadEmployeesFullProfileSuccess({ fullProfileEmployees })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    // // IMPORTANT: below code loads employees only if it is not loaded before. we had some issue with it since we need to reload when new client is selected.
    // loadEmployees$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(EmployeeActions.loadEmployees),
    //         withLatestFrom(this.store.select(state => state.main.employees)),
    //         filter(([action, employees]) => !employees || employees.length === 0), // only continue if it was not loaded before
    //         mergeMap(([action, employees]) => {
    //             const { clientId } = action; // Extract the clientId from the action
    //             //get Employees and profiles in parallel and then merge before putting into the state

    //             return this.employeeService.getEmployeesFullprofile(clientId).pipe(
    //                 map(employees => EmployeeActions.loadEmployeesSuccess({ employees })),
    //                 catchError(error => of(/* handle error action */))
    //             );
    //         })
    //     )
    // );

    //   createEmployee$ = createEffect(() =>
    //     this.actions$.pipe(
    //       ofType(EmployeeActions.createEmployee),
    //       mergeMap(({ employee }) =>
    //         this.employeeService.createEmployee(employee).pipe(
    //           map(createdEmployee => EmployeeActions.createEmployeeSuccess({ employee: createdEmployee })),
    //           catchError(error => of(/* handle error action */))
    //         )
    //       )
    //     )
    //   );

    updateEmployee$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeeActions.updateEmployee),
            mergeMap(({ organizationId, employee }) => {
                //delete employee paymentProfile
                return this.employeeService.updateEmployee(organizationId, employee).pipe(
                    map(updatedEmployee => EmployeeActions.updateEmployeeSuccess({ employee: updatedEmployee })),
                    catchError(error => of(/* handle error action */))
                )
            }
            )
        )
    );

    upsertEmployeePaymentProfile$ = createEffect(() =>
    this.actions$.pipe(
        ofType(EmployeeActions.upsertEmployeePaymentProfile),
        mergeMap(({ organizationId, paymentProfile }) => {
            //delete employee paymentProfile
            return this.employeeService.upsertEmployeePaymentProfile(organizationId, paymentProfile).pipe(
                map(updatedProfile => EmployeeActions.upsertEmployeePaymentProfileSuccess({ paymentProfile: updatedProfile })),
                catchError(error => of(/* handle error action */))
            )
        }
        )
    )
);

    //   deleteEmployee$ = createEffect(() =>
    //     this.actions$.pipe(
    //       ofType(EmployeeActions.deleteEmployee),
    //       mergeMap(({ id }) =>
    //         this.employeeService.deleteEmployee(id).pipe(
    //           map(() => EmployeeActions.deleteEmployeeSuccess({ id })),
    //           catchError(error => of(/* handle error action */))
    //         )
    //       )
    //     )
    //   );
}
