import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['website.component.scss']
})
export class WebsiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.loadScript("https://unpkg.com/jquery@3.6.1/dist/jquery.min.js");
    // this.loadScript("https://unpkg.com/isotope-layout@3.0.6/dist/isotope.pkgd.min.js");
    // this.loadScript("https://unpkg.com/isotope-packery@2.0.1/packery-mode.pkgd.min.js");
    // this.loadScript("https://unpkg.com/imagesloaded@5.0.0/imagesloaded.pkgd.min.js");
    this.loadScript("https://unpkg.com/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js");
  
    // this.loadScript('/assets/website/controller/project-2.js');
    // this.loadScript('/assets/website/controller/wave-bsb.js');
  }

  loadScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

}
