import { INavData } from '@coreui/angular-pro';
import { UserRole } from 'src/app/authentication/model';

export interface IExtendedNavData extends INavData {
  roles?: UserRole[];
}

export const navItems: IExtendedNavData[] = [
  {
    name: $localize`Dashboard`,
    url: '/secure/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    badge: {
      color: 'info',
      text: $localize`NEW`
    },
    roles: [UserRole.Any]
  },
  {
    name: $localize`Dashboard`,
    url: '/secure/driver/dashboard',
    iconComponent: { name: 'cil-speedometer' },
    badge: {
      color: 'info',
      text: $localize`NEW`
    },
    roles: [UserRole.Driver]
  },
  {
    title: true,
    name: $localize`Payments`,
    roles: [UserRole.Any]
  },
  {
    name: $localize`Payroll`,
    url: '/secure/payments/payrolls',
    iconComponent: { name: 'cil-money' },
    roles: [UserRole.ManagerAll, UserRole.Payroll]
  },
  {
    name: $localize`Schedule`,
    url: '/secure/schedule/weekly-schedules',
    iconComponent: { name: 'cil-calendar' },
    roles: [UserRole.ManagerAll]
  },
  {
    title: true,
    name: $localize`Reports`,
    roles: [UserRole.Any]
  },
  // {
  //   name: $localize`IVMR reports`,
  //   url: '/secure/ivmr/manage-ivmr',
  //   iconComponent: { name: 'cil-speedometer' },
  //   roles: [UserRole.Manager]
  // },
  {
    name: $localize`MMR reports`,
    url: '/secure/ivmr/mmr',
    iconComponent: { name: 'cil-graph' },
    roles: [UserRole.ManagerAll, UserRole.Ivmr]
  },
  {
    name: $localize`State Miles Report`,
    url: '/secure/ivmr/state-miles-report',
    iconComponent: { name: 'cil-graph' },
    roles: [UserRole.ManagerAll, UserRole.Ivmr]
  },
  {
    name: $localize`Timesheet Report`,
    url: '/secure/reports/driver-timesheet',
    iconComponent: { name: 'cil-graph' },
    roles: [UserRole.ManagerAll, UserRole.Ivmr]
  },
  {
    name: $localize`Fuel Report`,
    url: '/secure/reports/fuel',
    iconComponent: { name: 'cil-graph' },
    badge: {
      color: 'info',
      text: $localize`NEW`
    },
    roles: [UserRole.ManagerAll, UserRole.Fuel]
  },
  {
    name: $localize`Profit & Loss`,
    url: '/secure/reports/profit-and-loss',
    iconComponent: { name: 'cil-graph' },
    badge: {
      color: 'info',
      text: $localize`NEW`
    },
    roles: [UserRole.ManagerAll]
  },
  // {
  //   name: 'Invocing',
  //   url: '#',
  //   iconComponent: { name: 'cil-envelope-open' },
  //   children: [
  //     {
  //       name: 'Invoces',
  //       iconComponent: { name: 'cil-cash' },
  //       badge: {
  //         color: 'danger-gradient',
  //         text: 'PRO'
  //       },
  //       url: '/secure/invoicing/invoice'
  //     },
  //     {
  //       name: 'Invoce validation',
  //       badge: {
  //         color: 'success-gradient',
  //         text: 'NEW'
  //       },
  //       url: '#'
  //     }
  //   ]
  // }
];
