export class Payroll {
    id!: number;
    startDate!: string;
    endDate!: string
    periodName!: string
    status!: string
    payrollType!:PayrollType;
    clientId!: string;
    frequency!: PayrollFrequencies;
    readonly!: boolean;
    noOfEmployees!: number;
    totalMiles!: number;
    totalPayment!: number;
    totalNonTaxable!: number;
    payableHours?: EmployeePayableHour[]
}

export enum PayrollType {
    Standard = "standardPayroll",
    SafetyBonus = "safetyBonusPayroll"
}

export type PayrollSummary = {
    totalPayment: number;
    totalMiles: number;
    numberOfEmployees: number;
}

export enum PayrollFrequencies {
    BiWeekly = 'bi-weekly',
    Weekly = 'weekly'
}

export class PayrollTrip {
    index!: number;
    reviewStatus!: PayrollTripStatus;
    clientId!: string;
    vehicleNumber!: string;
    terminals!: string[]
    trips!: PayableTrip[]
    constructor() { }
}

export class PayableTrip {
    id!: number;
    startTerminal!: string
    endTerminal!: string;
    distance!: number;
    startTime!: string;
    driver1EmployeeId!: number;
    driver1Name!: string;
    driver1NameFromEmployeeSource!: string; //this field holds the employeeName retreived from Employees Api if the PayableTrip is missing driver1Name field. driver1Name should take precedence over this one
    driver2EmployeeId!: number;
    driver2Name!: string;
    driver2NameFromEmployeeSource!: string; //this field holds the employeeName retreived from Employees Api if the PayableTrip is missing driver1Name field. driver2Name should take precedence over this one
    sourceName!: string;
    orderWithinSameDay!: number;
    endWeekForEndOfTrip!: string;
    userActionStatus?: string
}

// Payable hours - Start
export type EmployeePayableHour = {
    employeeId: number;
    employeeName?: string;
    clientId: string;
    reviewStatus: PayableTimeStatus;
    index?: number;
    payableHours: PayableHour[];
    totalDuration: number;
    lineHaulTrips?: PayableTrip[]
}

export type PayableHour = {
    id?: number;
    vehicleNumber?: string;
    startTime: string;
    endTime: string;
    duration?: number;
    orderWithinSameDay?: number;
    userActionStatus?: string;
    eventTimes?: PayableHourEventTime
}

export type PayableHourEventTime = {
    startTime: string;
    endTime: string;
    date: string;
}


// Payable days - Start
export type EmployeePayableDay = {
    employeeId: number;
    employeeName?: string;
    clientId: string;
    reviewStatus: PayableTimeStatus;
    index?: number;
    payableDays: PayableDay[];
    totalDays: number;
    lineHaulTrips?: PayableTrip[]
}

export type PayableDay = {
    date: string;
    payable?: boolean;
    userActionStatus?: string
}

export enum PayableTimeStatus {
    Completed = 'completed',
    Pending = 'pending',
    NoRecords = 'no-records'
}

// Payable hours - End


export class Validation {
    status!: ValidationStatus;
    message!: string
    messageType!: MessageType
    updatedPayRoll!: Payroll
    constructor() { }
}

export class Paystub {
    id!: number;
    clientId!: string;
    employeeFullName!: string
    employeeId!: number
    paystubType!: PaystubType
    workingDays!: number
    perDiemRate!: number
    //week 1 properties
    week1TeamMiles!: number
    week1SoloMiles!: number
    // week1TeamPayRate!: number
    // week1SoloPayRate!: number
    week1PayRates!: any
    // week1SuplementalRate!: number
    week1HourlyRate?: number
    week1DailyRate?: number
    week1PayableTime?: number
    week1PayableDays?: number

    // week 2 properties
    week2TeamMiles!: number
    week2SoloMiles!: number
    // week2TeamPayRate!: number
    // week2SoloPayRate!: number
    week2PayRates!: any
    // week2SuplementalRate!: number
    week2HourlyRate?: number
    week2DailyRate?: number
    week2PayableTime?: number
    week2PayableDays?: number

    totalPayment!: number
    userActionStatus?: UserActionStatus
    payRateTypes!: any
    perDiemPayment!: number;
    regularPaymentTotal!: number
    regularPaymentItems!: any;
    otherPaymentItems!: PaystubOtherAmounts
    otherPaymentTotal!: number
    excludedFromPayroll!: boolean
    excludedBy!: string
    userCreated!: boolean
    paystubReportedMiles!: number
    salaryPayment?: number

    constructor() { }
}

export type PayComponentState = {
    [key: string]: PayComponent[];
}

export type PayComponent = {
    type: ComponentTypeEnum,
    title: string,
    selected: boolean,
    group: PayComponentGroup,
    componentId: string,
    payrollProcessorId: string,
    uiSettings: PayComponentUiSetting
}
export type PayComponentUiSetting = {
    format: ComponentFormatEnum,
    otherPaymentsScreen: PayComponentUiSettingScreen,
    regularPaymentScreen: PayComponentUiSettingScreen
}

export enum PayComponentGroup {
    OtherPayment = 'otherPayment',
    RegularPayment = 'regularPayment'
}

export type PayComponentUiSettingScreen = {
    edit: boolean,
    selected: boolean,
    show: boolean
}

export enum ComponentTypeEnum {
    Addition = "addition",
}
export enum ComponentFormatEnum {
    Decimal = "decimal",
    Money = "money"
}

export enum PaystubType {
    ByMiles = 'byMiles',
    Hourly = 'hourly',
    Daily = 'daily',
    Salary = 'salary',
    Other = 'other'
}

export type PaystubOtherAmounts = {
    gasReimbursment?: number;
}

export class DriverIdsGroup {
    driver1EmployeeId!: number;
    driver1Name!: string;
    driver2EmployeeId!: number;
    driver2Name!: string;

}

export enum MessageType {
    Success = "success",
    Warning = "warning",
    Error = "danger"
}

export enum ValidationStatus {
    Success = "success",
    Fail = "fail"
}

export enum UserActionStatus {
    Create = "create",
    Update = "update",
    Delete = "delete",
    Exclude = "exclude"
}

export enum PayrollStatus {
    NotStarted = 'not started',
    PaystubsGenerated = 'paystubs generated',
    RegularPaymentCalculated = 'regular payment calculated',
    PaystubsConfirmed = 'paystubs confirmed',
    PayrollSubmitted = 'payroll submitted'
}

export enum PayrollTripStatus {
    Completed = 'completed',
    Pending = 'pending'
}


export enum TripWeek {
    Week1 = 1,
    Week2 = 2
}