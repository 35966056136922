<ng-container >
  <div class="toastContainer">
    <c-toast #toast="cToast" [autohide]="false" [visible]="true" class="align-items-center text-white border-0 toastFormat" [color]="color">
      <div class="d-flex">
        <c-toast-body>
          <!-- <c-alert color="success">{{message}}</c-alert> -->
          {{message}}
        </c-toast-body>
        <button aria-label="close" cButtonClose class="me-2 m-auto" (click)="toast.visible=false"></button>
      </div>
    </c-toast>
  </div>
  </ng-container>