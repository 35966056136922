<c-sidebar
  #aside1="cSidebar"
  class="d-print-none hide"
  colorScheme="light"
  id="aside1"
  overlaid
  placement="end"
  size="lg"
>
  <!--aside-header -->
  <c-sidebar-header class="bg-transparent">
    <!--aside toggler-->
    <button
      cButtonClose
      cSidebarToggle="aside1"
      class="float-end"
      toggle="visible"
    ></button>
  </c-sidebar-header>
</c-sidebar>
