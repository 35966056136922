import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from 'aws-amplify';
import { AuthService } from './authentication.service';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, private _authenticationService: AuthService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      const routes = route && route.data && route.data['roles'];
      const hasAccess = await this._authenticationService.checkAccess(routes || []);
      // console.log(hasAccess);
      if (!hasAccess) {
        // role not authorised so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      // authorised so return true
      return true;
    }
    catch (err) {
      console.log(err)
      this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
