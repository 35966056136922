
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SchedulingService } from '../services/schedule.service';
import * as RunSegmentActions from '../actions/run-segment.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable()
export class RunSegmentEffects {
    constructor(private actions$: Actions, private schedulingService: SchedulingService, private store: Store<AppState>) { }
    updateRunSegment$ = createEffect(() => this.actions$.pipe(
        ofType(RunSegmentActions.updateRunSegment),
        mergeMap(({ organizationId, weeklyScheduleId, runSegment }) => this.schedulingService.updateRunSegment(organizationId, weeklyScheduleId, runSegment).pipe(
            map((rs) => {
                return RunSegmentActions.updateRunSegmentSuccess({ runSegment: rs });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    createRunSegment$ = createEffect(() => this.actions$.pipe(
        ofType(RunSegmentActions.createRunSegment),
        mergeMap(({ organizationId, weeklyScheduleId, runSegment }) => this.schedulingService.createRunSegment(organizationId, weeklyScheduleId, runSegment).pipe(
            map((rs) => {
                // console.log(ScheduledRun);
                return RunSegmentActions.createRunSegmentSuccess({ runSegment: rs });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    deleteRunSegment$ = createEffect(() => this.actions$.pipe(
        ofType(RunSegmentActions.deleteRunSegment),
        mergeMap(({ organizationId, weeklyScheduleId, scheduledRunId, id }) => this.schedulingService.deleteRunSegment(organizationId, weeklyScheduleId, scheduledRunId, id).pipe(
            map((rsId) => {
                // console.log(ScheduledRun);
                return RunSegmentActions.deleteRunSegmentSuccess({ scheduledRunId, id });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

}
