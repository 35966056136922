export class CurrentUser{
    constructor(name: string, roles: string[]){
        this.name = name;
        this.roles = roles;
    }
    name: string;
    roles: string[];
}

export enum UserRole {
    Any = '*',
    Manager = 'manager',
    ManagerAll = 'manager_all', 
    Ivmr = 'ivmr',
    Driver = 'driver',
    Fuel = 'fuel',
    Payroll = 'payroll',
    DemoAdmin = 'demo-admin'
}