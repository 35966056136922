import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent, SecureLayoutComponent } from './containers';
import {WebsiteComponent} from './views/website/website.component'
import { TripDriverConfirmedComponent } from './views/secure/schedule/weekly-schedule/trip-driver-confirmed/trip-driver-confirmed.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: WebsiteComponent,
    pathMatch: 'prefix',
    data: {
      title: $localize`Home`
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: $localize`Home`
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      }
    ]
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: $localize`Home`
    },
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('./views/public/auth/auth.module').then((m) => m.AuthModule)
      }
    ]
  },
  {
    path: 'confirmtrip/:tripId/:driverId',
    component: TripDriverConfirmedComponent
  },
  {
    path: '',
    component: SecureLayoutComponent,
    data: {
      title: $localize`Home`
    },
    children: [
      {
        path: 'secure',
        loadChildren: () =>
          import('./views/secure/secure.module').then((m) => m.SecureModule)
      }
    ]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
