import { createAction, props } from '@ngrx/store';
import { Payroll, Paystub } from '../models/payroll.model';

// Load Payrolls
export const loadPayrolls = createAction('[Payment->Payrolls] Load Payrolls', props<{ organizationId: string, clientId: string }>());
export const loadPayrollsSuccess = createAction('[Payment->Payrolls] Load Payrolls Success', props<{ payrolls: Payroll[] }>());

export const loadPayrollByID = createAction('[Payment->Payrolls] Load Payroll By ID', props<{ organizationId: string, clientId: string, payrollId: number }>());
export const loadPayrollByIDSuccess = createAction('[Payment->Payrolls] Load Payroll By ID Success', props<{ payroll: Payroll }>());
export const clearSelectedPayroll = createAction('[Payment->Payrolls] Clear Selected Payroll');

// Generate payroll
export const generatePayroll = createAction('[Payment->Payrolls] Generate Payroll', props<{ organizationId: string, clientId: string, payrollType: string }>());
export const generatePayrollSuccess = createAction('[Payment->Payrolls] Generate Payroll Success', props<{ payroll: Payroll }>());

// Generate Paystubs
export const generatePaystubs = createAction('[Payment->Payrolls] Generate Paystubs', props<{ organizationId: string, clientId: string, payrollId: number }>());
export const generatePaystubsSuccess = createAction('[Payment->Payrolls] Generate Paystubs Success', props<{ payroll: Payroll }>()); 


// Enable PayableTrips edit for a payroll
export const enablePayableTripsEdit = createAction('[Payment->Payrolls] Enable Payable Trips Edit', props<{ organizationId: string, clientId: string, payrollId: number }>());
export const enablePayableTripsEditSuccess = createAction('[Payment->Payrolls] Enable Payable Trips Edit Success', props<{ payroll: Payroll }>());

// Confirm Paystubs
export const saveRegularPayments = createAction('[Payment->Payrolls] Save Regular Payments', props<{ organizationId: string, clientId: string, payrollId: number, payStubs: Paystub[], changesCompleted: boolean }>());
export const saveRegularPaymentsSuccess = createAction('[Payment->Payrolls] Save Regular Payments Success', props<{ payroll: Payroll }>()); 
export const savePaystubAmounts = createAction('[Payment->Payrolls] Save Paystub Amounts', props<{ organizationId: string, clientId: string, payrollId: number, payStubs: Paystub[], changesCompleted: boolean }>());
export const savePaystubAmountsSuccess = createAction('[Payment->Payrolls] Save Paystub Amounts Success', props<{ payroll: Payroll }>()); 

// Navigate through Payroll flow
export const payrollFlowNext = createAction('[Payment->Payrolls] Next Step');
export const payrollFlowPreviuos = createAction('[Payment->Payrolls] Previuous Step');
export const payrollFlowSetActiveStep = createAction('[Payment->Payrolls] Set Active Step', props<{ stepId: string }>());
