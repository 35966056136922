import { createReducer, on } from '@ngrx/store';
import { Employee, Vehicle } from '../models/main.models';
import * as EmployeeActions from '../actions/employee.actions';
import * as VehicleActions from '../actions/vehicle.actions';
import * as ClientActions from '../actions/client.actions';

export interface MainState {
    activeClientId?: string;
    employees?: Employee[];
    fullProfileEmployees: Employee[];
    selectedEmployee?: Employee;
    vehicles: Vehicle[];
}

export const initialState: MainState = {
    // employees: [],
    fullProfileEmployees: [],
    vehicles: []
};

export const mainReducer = createReducer(
    initialState,
    //Clients
    on(ClientActions.setActiveClientId, (state, { clientId }) => ({ ...state, activeClientId: clientId })),

    //Employees
    on(EmployeeActions.loadEmployeesSuccess, (state, { employees }) => ({ ...state, employees })),
    on(EmployeeActions.loadEmployeesFullProfileSuccess, (state, { fullProfileEmployees }) => ({ ...state, fullProfileEmployees })),
    on(EmployeeActions.loadEmployeeByIdSuccess, (state, { selectedEmployee }) => ({ ...state, selectedEmployee })),
    on(EmployeeActions.cleanSelectedEmployee, (state, { }) => ({ ...state, selectedEmployee:undefined })),
    on(EmployeeActions.loadEmployeeFullProfileByIDSuccess, (state, { selectedEmployee }) => ({ ...state, selectedEmployee })),
    //   on(EmployeeActions.createEmployeeSuccess, (state, { employee }) => ({ ...state, employees: [...state.employees, employee] })),
    on(EmployeeActions.updateEmployeeSuccess, (state, { employee }) => ({
        ...state,
        selectedEmployee: { ...employee, paymentProfile: state.selectedEmployee?.paymentProfile }
    })),
    on(EmployeeActions.upsertEmployeePaymentProfileSuccess, (state, { paymentProfile }) => ({
        ...state,
        selectedEmployee: state.selectedEmployee ? { ...state.selectedEmployee, paymentProfile } : undefined
    })),
    //   on(EmployeeActions.deleteEmployeeSuccess, (state, { id }) => ({
    //     ...state,
    //     employees: state.employees.filter(e => e.id !== id)
    //   }))

    //Vehicles
    on(VehicleActions.loadVehiclesSuccess, (state, { vehicles }) => ({ ...state, vehicles })),

);
