import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import * as EmployeePaymentProfileActions from '../actions/employee-payment-profile.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PaymentsService } from '../services/payroll.service';
import { PayrollPaychexService } from '../services/paychex.service';

@Injectable()
export class PayrateConfigEffects {
    constructor(private actions$: Actions, private paymentService: PaymentsService, private paychexService: PayrollPaychexService, private store: Store<AppState>) { }

    getPayrateConfigs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeePaymentProfileActions.loadPayrateConfigurations),
            withLatestFrom(this.store.select(state => state.payment.payrateConfigs)),
            filter(([action, payrateConfigs]) => !payrateConfigs), // only continue if it was not loaded before
            mergeMap(([action, payrateConfigs]) => {
                const { organizationId, clientId } = action; // Extract the clientId and organizationId from the action
                
                return this.paymentService.getPayrateConfigs(organizationId, clientId).pipe(
                    map(payrateConfigs => EmployeePaymentProfileActions.loadPayrateConfigurationsSuccess({ payrateConfigs })),
                    catchError(error => of(/* handle error action */))
                );
            })
        )
    );


    getPaychexWorkers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EmployeePaymentProfileActions.loadPaychexWorkers),
            withLatestFrom(this.store.select(state => state.payment.payrollProcessorEmployees)),
            filter(([action, workers]) => !workers?.[action.clientId]), // only continue if it was not loaded before
            mergeMap(([action, workers]) => {
                const { organizationId, clientId } = action; // Extract the clientId and organizationId from the action
                
                return this.paychexService.getWorkers(organizationId, clientId).pipe(
                    map(paychexWorkers => EmployeePaymentProfileActions.loadPaychexWorkersSuccess({ clientId, paychexWorkers })),
                    catchError(error => of(/* handle error action */))
                );
            })
        )
    );

}
