import { createAction, props } from '@ngrx/store';
import { Employee, EmployeePaymentProfile } from '../models/main.models';

// Load Employees
export const loadEmployees = createAction('[Employee] Load Employees', props<{ organizationId: string, showDeletedEmployees?: boolean }>());
export const loadEmployeesSuccess = createAction('[Employee] Load Employees Success', props<{ employees: Employee[] }>());

// Load EmployeeByID
export const loadEmployeeById = createAction('[Employee] Load Employee By ID', props<{ organizationId: string, employeeId: number }>());
export const loadEmployeeByIdSuccess = createAction('[Employee] Load Employee By ID Success', props<{ selectedEmployee: Employee }>());
export const cleanSelectedEmployee = createAction('[Employee] Clean Selected Employee');


// Load loadEmployeeFullProfileByID -- This loads employee, payment profile and any other info needed
export const loadEmployeeFullProfileByID = createAction('[Employee] Load Employee Full Profile By ID', props<{ organizationId: string, employeeId: number }>());
export const loadEmployeeFullProfileByIDSuccess = createAction('[Employee] Load Employee Full Profile By ID Success', props<{ selectedEmployee: Employee }>());


// Load Employees Full Profile
export const loadEmployeesFullProfile = createAction('[Employee] Load Employees Full Profile', props<{ organizationId: string }>());
export const loadEmployeesFullProfileSuccess = createAction('[Employee] Load Employees Full Profile Success', props<{ fullProfileEmployees: Employee[] }>());


// // Create Employee
// export const createEmployee = createAction('[Employee] Create Employee', props<{ employee: Employee }>());
// export const createEmployeeSuccess = createAction('[Employee] Create Employee Success', props<{ employee: Employee }>());

// Update Employee
export const updateEmployee = createAction('[Employee] Update Employee', props<{ organizationId: string, employee: Employee }>());
export const updateEmployeeSuccess = createAction('[Employee] Update Employee Success', props<{ employee: Employee }>());

// Update Employee
export const upsertEmployeePaymentProfile = createAction('[Employee] Upsert Employee Payment Profile', props<{ organizationId: string, paymentProfile: EmployeePaymentProfile }>());
export const upsertEmployeePaymentProfileSuccess = createAction('[Employee] Upsert Employee Payment Profile Success', props<{ paymentProfile: EmployeePaymentProfile }>());


// // Delete Employee
// export const deleteEmployee = createAction('[Employee] Delete Employee', props<{ id: number }>());
// export const deleteEmployeeSuccess = createAction('[Employee] Delete Employee Success', props<{ id: number }>());
