import {PayrollEffects} from '../effects/payroll.effects'
import {PayableTripEffects} from './payable-trip.effects'
import {PaystubEffects} from './paystub.effects'
import {PayrateConfigEffects} from './employee-payment-profile.effects'

export const paymentEffects = [
    PayrollEffects,
    PayableTripEffects,
    PaystubEffects,
    PayrateConfigEffects
]