import {
  cilApplicationsSettings,
  cilBrowser,
  cilContrast,
  cilMenu,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilCalendar,
  cilMoney,
  cilGraph,
  cilNotes,
  cilSettings,
  cilUser,
  cilEnvelopeOpen,
  cilLockLocked,
  cilBell,
  cilTruck,
  cilBarChart
} from '@coreui/icons';

import { signet } from './signet';
import { logo } from './logo';

export const iconSubset = {
  cilApplicationsSettings,
  cilBrowser,
  cilContrast,
  cilMenu,
  cilMoon,
  cilSpeedometer,
  cilSun,
  logo,
  signet,
  cilCalendar,
  cilMoney,
  cilGraph,
  cilNotes,
  cilSettings ,
  cilUser,
  cilEnvelopeOpen,
  cilLockLocked,
  cilBell,
  cilTruck,
  cilBarChart
};

export enum IconSubset {
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilBrowser = 'cilBrowser',
  cilContrast = 'cilContrast',
  cilMenu = 'cilMenu',
  cilMoon = 'cilMoon',
  cilSpeedometer = 'cilSpeedometer',
  cilSun = 'cilSun',
  logo = 'logo',
  signet = 'signet',
  
  cilCalendar = 'cilCalendar',
  cilMoney = 'cil-money',
  cilGraph = 'cil-graph',
  cilBarChart = 'cilBarChart',
  cilNotes = 'cilNotes',
  cilSettings  = 'cilSettings',
  cilUser = 'cilUser',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilLockLocked = 'cilLockLocked',
  cilBell = 'cilBell',
  cilTruck = 'cilTruck'
}
