import { createAction, props } from '@ngrx/store';
import { RunSegment, ScheduledRun } from '../models/schedule.model';

// Load Scheduled Runs
export const loadScheduledRuns = createAction('[ScheduledRun] Load Scheduled Run', props<{ organizationId: string, clientId: string, weeklyScheduleId: string  }>());
export const loadScheduledRunsSuccess = createAction('[ScheduledRun] Load Scheduled Run Success', props<{ scheduledRuns: ScheduledRun[] }>());

// Update Weekly Schedule
export const updateScheduledRun = createAction('[ScheduledRun] Update Scheduled Run', props<{organizationId: string, scheduledRun: ScheduledRun }>());
export const updateScheduledRunSuccess = createAction('[ScheduledRun] Update Scheduled Run Success', props<{ scheduledRun: ScheduledRun }>());

// Create Weekly Schedule
export const createScheduledRun = createAction('[ScheduledRun] Create Scheduled Run', props<{organizationId: string, scheduledRun: ScheduledRun }>());
export const createScheduledRunSuccess = createAction('[ScheduledRun] Create Scheduled Run Success', props<{ scheduledRun: ScheduledRun }>());

