
//Has all the steps of Payroll flow and data needed to navigate from step to step.

import { PayrollStatus, PayrollFrequencies, PayrollType } from "../models/payroll.model";

// Create payroll flow customized for given frequency
export const getPayrollFlow = (payrollType: PayrollType | undefined, payrollFrequency: PayrollFrequencies | undefined): PayrollFlow => {
    let payrollFlowSteps: PayrollStep[] = [];
    if (payrollType === PayrollType.Standard) {
        payrollFlowSteps = STANDARD_PAYROLL_FLOW_STEPS
    } else if (payrollType === PayrollType.SafetyBonus) {
        payrollFlowSteps = SAFETY_BONUS_PAYROLL_FLOW_STEPS
    }
    const steps = payrollFlowSteps.filter(s => !s.filterConditions || s.filterConditions.frequency === payrollFrequency)
    return {
        steps,
        activeStepIndex: 0
    }
}

//TODO: evaluate if submitButtonText, enableEdit.. should come from this config or how they are built not from control
const STANDARD_PAYROLL_FLOW_STEPS: PayrollStep[] = [
    {
        id: "confirm-week1-trips",
        submitButtonText: "Save & Continue",
        stepText: "Week 1 Trips",
        payrollStatuses: [PayrollStatus.NotStarted],
        enableEditConfirmationText: "Enabling trip detail editing will discard any changes done on the 'Paystubs' step."
    },
    {
        id: "confirm-week2-trips",
        submitButtonText: "Save & Continue",
        stepText: "Week 2 Trips",
        payrollStatuses: [PayrollStatus.NotStarted],
        filterConditions: { frequency: PayrollFrequencies.BiWeekly }, // This will make sure this step is available only if thecondition is met
        enableEditConfirmationText: "Enabling trip detail editing will discard any changes done on the 'Paystubs' step."
    },
    {
        id: "calculate-regular-payment",
        submitButtonText: "Save & Continue",
        saveButtonText: "Save",
        payrollStatuses: [PayrollStatus.PaystubsGenerated],
        stepText: "Regular Payment",
    },
    {
        id: "confirm-other-amounts",
        submitButtonText: "Save & Continue",
        saveButtonText: "Save",
        payrollStatuses: [PayrollStatus.RegularPaymentCalculated],
        stepText: "Other Amounts",
    },
    {
        id: "submit-payroll",
        submitButtonText: "Finalize",
        payrollStatuses: [PayrollStatus.PaystubsConfirmed, PayrollStatus.PayrollSubmitted],
        stepText: "Save Paystubs",
        hideNavigator: true
    }

]

const SAFETY_BONUS_PAYROLL_FLOW_STEPS: PayrollStep[] = [
    {
        id: "confirm-safety-scores",
        submitButtonText: "Save & Continue",
        stepText: "Validate Paystubs",
        payrollStatuses: [PayrollStatus.NotStarted, PayrollStatus.PaystubsGenerated]
    },
    {
        id: "submit-payroll",
        submitButtonText: "Finalize",
        payrollStatuses: [PayrollStatus.RegularPaymentCalculated, PayrollStatus.PaystubsConfirmed, PayrollStatus.PayrollSubmitted],
        stepText: "Submit Paystubs",
        hideNavigator: true
    }

]

export class PayrollStep {
    id!: string;
    submitButtonText!: string;
    saveButtonText?: string;
    stepText!: string
    payrollStatuses!: PayrollStatus[] | string[]; //This is the list of statuses that Payroll is in this step. Usually is one but there is an exception for last step
    enableEditConfirmationText?: string  //TODO: this is not getting used yet. We will reevaluate
    filterConditions?: PayrollStepCondition;
    hideNavigator?: boolean
}

type PayrollStepCondition = {
    frequency: PayrollFrequencies
}

export type PayrollFlow = {
    steps: PayrollStep[],
    activeStepIndex: number
}

