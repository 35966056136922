import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { SchedulingService } from '../services/schedule.service';
import * as WeeklyScheduleActions from '../actions/weekly-schedule.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable()
export class WeeklyScheduleEffects {
    constructor(private actions$: Actions, private schedulingService: SchedulingService, private store: Store<AppState>) { }

    loadWeeklySchedules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WeeklyScheduleActions.loadWeeklySchedules),
            mergeMap(({ organizationId }) =>
                this.schedulingService.getWeeklySchedules(organizationId).pipe(
                    map(weeklySchedules => {
                        // console.log(weeklySchedules)
                        return WeeklyScheduleActions.loadWeeklySchedulesSuccess({ weeklySchedules })
                    }),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    generateNextWeeklySchedules$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WeeklyScheduleActions.generateNextWeeklySchedule),
            mergeMap(({ organizationId }) =>
                this.schedulingService.generateNextWeeklySchedule(organizationId).pipe(
                    map(createWeeklySchedule => WeeklyScheduleActions.generateNextWeeklyScheduleSuccess({ weeklySchedule: createWeeklySchedule })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );

    loadSelectedWeeklySchedule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WeeklyScheduleActions.loadSelectedWeeklySchedule),
            mergeMap(({ organizationId, weeklyScheduleId }) => {
                const selectedWeeklySchedule$ = this.schedulingService.getWeeklyScheduleById(organizationId, weeklyScheduleId);
                const scheduledRuns$ = this.schedulingService.getScheduledRuns(organizationId, weeklyScheduleId);
                const combined$ = combineLatest([selectedWeeklySchedule$, scheduledRuns$]).pipe(
                    map(([weeklySchedule, scheduledRuns]) => {
                        if (weeklySchedule) {
                            weeklySchedule.scheduledRuns = scheduledRuns;
                        }
                        return WeeklyScheduleActions.loadSelectedWeeklyScheduleSuccess({ weeklySchedule })
                    }),
                    catchError(error => of(/* handle error action */))
                )
                return combined$;
            }
            )
        )
    );

    sendRunSegmentNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(WeeklyScheduleActions.sendRunSegmentsNotifications),
            mergeMap(({ organizationId, weeklyScheduleId, runSegments }) =>
                this.schedulingService.sendRunSegmentNotifications(organizationId, weeklyScheduleId, runSegments).pipe(
                    map(updatedRunSegments => WeeklyScheduleActions.sendRunSegmentsNotificationsSuccess({ runSegments: updatedRunSegments })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );
}