import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import * as PayrollActions from '../actions/payroll.actions';
import * as PaystubActions from '../actions/paystub.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PaymentsService } from '../services/payroll.service';
import { Payroll, PayComponent } from '../models/payroll.model';

@Injectable()
export class PaystubEffects {
    constructor(private actions$: Actions, private paymentService: PaymentsService, private store: Store<AppState>) { }

    saveRegularPayments$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.saveRegularPayments),
        mergeMap(({ organizationId, clientId, payrollId, payStubs, changesCompleted }) => this.paymentService.saveRegularPayments(organizationId, clientId, payrollId, payStubs, changesCompleted).pipe(
            map((payroll: Payroll) => {
                // console.log('save regular payments effect triggered')
                return PayrollActions.saveRegularPaymentsSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    confirmPaystubs$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.savePaystubAmounts),
        mergeMap(({ organizationId, clientId, payrollId, payStubs, changesCompleted }) => this.paymentService.savePaystubAmounts(organizationId, clientId, payrollId, payStubs, changesCompleted).pipe(
            map((payroll: Payroll) => {
                // console.log('confirm paystubs effect triggered')
                return PayrollActions.savePaystubAmountsSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    // getPaystubComponents$ = createEffect(() => this.actions$.pipe(
    //     ofType(PaystubActions.loadPaystubComponents),
    //     mergeMap(({ organizationId, clientId }) => this.paymentService.getPaystubComponents(organizationId, clientId).pipe(
    //         map((paystubComponents: PaystubComponent[]) => {
    //             return PaystubActions.loadPaystubComponentsSuccess({ paystubComponents });
    //         }),
    //         catchError(error => of( /* handle error action */))
    //     )
    //     )
    // )
    // );

    getPaystubComponents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaystubActions.loadPayComponents),
            withLatestFrom(this.store.select(state => state.payment.paystubComponents)),
            filter(([action, paystubComponents]) => !paystubComponents || paystubComponents.length === 0), // only continue if it was not loaded before
            mergeMap(([action, paystubComponents]) => {
                const { organizationId, clientId } = action; // Extract the clientId and organizationId from the action
                
                return this.paymentService.getPayComponents(organizationId, clientId).pipe(
                    map(paystubComponents => PaystubActions.loadPayComponentsSuccess({ paystubComponents })),
                    catchError(error => of(/* handle error action */))
                );
            })
        )
    );


}
