import { createAction, props } from '@ngrx/store';
import { RunSegment, WeeklySchedule } from '../models/schedule.model';

// Load Weekly Schedules
export const loadWeeklySchedules = createAction('[WeeklySchedule] Load Weekly Schedules', props<{organizationId:string }>());
export const loadWeeklySchedulesSuccess = createAction('[WeeklySchedule] Load Weekly Schedules Success', props<{ weeklySchedules: WeeklySchedule[] }>());

// Generate next Weekly Schedule
export const generateNextWeeklySchedule = createAction('[WeeklySchedule] Generate Next Weekly Schedule', props<{organizationId:string}>());
export const generateNextWeeklyScheduleSuccess = createAction('[WeeklySchedule] Generate Next Weekly Schedule Success', props<{ weeklySchedule: WeeklySchedule }>());


// Load Selected Weekly Schedule
export const loadSelectedWeeklySchedule = createAction('[WeeklySchedule] Load Selected Weekly Schedule', props<{organizationId:string, weeklyScheduleId: string }>());
export const loadSelectedWeeklyScheduleSuccess = createAction('[WeeklySchedule] Load Selected Weekly Schedule Success', props<{ weeklySchedule: WeeklySchedule }>());
export const clearSelectedWeeklySchedule = createAction('[WeeklySchedule] Clear Selected Weekly Schedule');

// Send nofitifications for Run Segments
export const sendRunSegmentsNotifications = createAction('[Schedule->WeeklySchedule] Send Run Segment Notifications', props<{ organizationId: string, weeklyScheduleId: number, runSegments: RunSegment[] }>());
export const sendRunSegmentsNotificationsSuccess = createAction('[Schedule->WeeklySchedule] Send Run Segment Notifications Success', props<{ runSegments: RunSegment[] }>());
