import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payroll, EmployeePayableHour, PayableHour, EmployeePayableDay, PayableDay } from '../models/payroll.model'
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class PayrollPayableItemsService {

    resourceUrl: string = environment.apiEndpoint;

    constructor(private http: HttpClient) {
    }

    getEmployeePayableHours(organizationId: string, clientId: String, payrollId: number, weekNumber: number, isApproved?: boolean): Observable<EmployeePayableHour[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/employee-payable-hours?payrollId=${payrollId}&weekNumber=${weekNumber}`;
        if (isApproved !== undefined) {
            url += `&isApproved=${isApproved}`
        }
        return this.http.get<EmployeePayableHour[]>(url);
    }

    postEmployeePayableHours(organizationId: string, clientId: String, employeeId: number, body: PayableHour[]) {
        console.log(body)
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/employee-payable-hours/${employeeId}`;
        return this.http.post(url, body);
    }

    getEmployeePayableDays(organizationId: string, clientId: String, payrollId: number, weekNumber: number, isApproved?: boolean): Observable<EmployeePayableDay[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/employee-payable-days?payrollId=${payrollId}&weekNumber=${weekNumber}`;
        if (isApproved !== undefined) {
            url += `&isApproved=${isApproved}`
        }
        return this.http.get<EmployeePayableDay[]>(url);
    }

    postEmployeePayableDays(organizationId: string, clientId: String, employeeId: number, body: PayableDay[]) {
        console.log(body)
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/employee-payable-days/${employeeId}`;
        return this.http.post(url, body);
    }

}
