<div *ngIf="tripConfirmationDetails">
    <div class="confirmImage row d-flex justify-content-center text-center">
        <div class="col-12">
            <img src="assets/img/icons/check.png" alt="confirm" width="80">
        </div>
    </div>
    <div class="tripConfirm row d-flex justify-content-center text-center">
        <div class="col-12">
            <h4> Trip Confirmed!</h4>
        </div>
    </div>
    <div class="tripConfirm row d-flex justify-content-center text-center">
        <div class="col-md-5 col-lg-4 col-10">
            <hr />
        </div>
    </div>
    
    <div class="tripDetails row d-flex justify-content-center text-center">
        <div class="col-12">
            Destination: {{tripConfirmationDetails.destination}}
        </div>
        <div class="col-12">
            Date: {{tripConfirmationDetails.tripDate}}
        </div>
        <div class="col-12">
            Time: {{tripConfirmationDetails.departureTime?.substring(0,5)}}
        </div>
    </div>
</div>