import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as PayrollActions from '../actions/payroll.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PaymentsService } from '../services/payroll.service';
import { Payroll } from '../models/payroll.model';
import * as UiActions from 'src/app/store/ui/actions/ui.actions';
import { MessageType } from 'src/app/views/secure/manage/model';


@Injectable()
export class PayrollEffects {
    constructor(private actions$: Actions, private paymentService: PaymentsService, private store: Store<AppState>) { }

    loadPayrolls$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.loadPayrolls),
        mergeMap(({ organizationId, clientId }) => this.paymentService.getPayrolls(organizationId, clientId).pipe(
            map((payrolls: Payroll[]) => {
                return PayrollActions.loadPayrollsSuccess({ payrolls });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    loadPayrollById$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.loadPayrollByID),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.getPayrollById(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.loadPayrollByIDSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    generatePayroll$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.generatePayroll),
        mergeMap(({ organizationId, clientId, payrollType }) => this.paymentService.generatePayroll(organizationId, clientId, payrollType).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.generatePayrollSuccess({ payroll });
            }),
            catchError(error => {
                return of(UiActions.serviceCallFailure({action: PayrollActions.generatePayroll, error }))
            })
        )
        )
    )
    );

    generatePaystubs$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.generatePaystubs),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.generatePaystubs(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                console.log('generate paystubs effect triggered')
                return PayrollActions.generatePaystubsSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    enablePayableTripsEdit$ = createEffect(() => this.actions$.pipe(
        ofType(PayrollActions.enablePayableTripsEdit),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.enablePayableTripsEdit(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                return PayrollActions.enablePayableTripsEditSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );
}
