import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CompanyPayPeriod, PayrollProcessorEmployee } from '../models/paychex.model';

@Injectable({ providedIn: 'root' })
export class PayrollPaychexService {

    resourceUrl: string = environment.apiEndpoint;

    constructor(private http: HttpClient) {
    }

    getCompanyPayPeriods(organizationId: string, clientId: string, payrollId: number): Observable<CompanyPayPeriod[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/pay-periods?payrollId=${payrollId}`;

        return this.http.get<CompanyPayPeriod[]>(url);
    }

    getWorkers(organizationId: string, clientId: string): Observable<PayrollProcessorEmployee[]> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/workers`;

        return this.http.get<PayrollProcessorEmployee[]>(url);
    }

    comparePaystubs(organizationId: string, clientId: string, payrollId: number, payPeriodId: string): Observable<any> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/compare-paystubs?payPeriodId=${payPeriodId}&payrollId=${payrollId}`;
        return this.http.post<any>(url, {});
    }

    deletePaystubs(organizationId: string, clientId: string, payrollId: number, payPeriodId: string): Observable<any> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/delete-transmited-paystubs?payPeriodId=${payPeriodId}&payrollId=${payrollId}`;
        return this.http.post<any>(url, {});
    }

    transmitPaystubs(organizationId: string, clientId: string, payrollId: number, payPeriodId: string, paystubs: any[]): Observable<any> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/transmit-paystubs?payPeriodId=${payPeriodId}&payrollId=${payrollId}`;
        return this.http.post<any>(url, paystubs);
    }

    validatePaystubs(organizationId: string, clientId: string, payrollId: number, payPeriodId: string): Observable<any> {
        let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/payment/payroll-processor/paychex/validate-paystubs?payPeriodId=${payPeriodId}&payrollId=${payrollId}`;
        return this.http.post<any>(url, {});
    }
    
}
