import { createReducer, on } from '@ngrx/store';
import * as PayrollActions from '../actions/payroll.actions';
import * as PayableTripsActions from '../actions/payable-trips.actions'
import * as EmployeePaymentProfileActions from '../actions/employee-payment-profile.actions'
import * as PayableHourActions from '../actions/payable-hours.actions'
import * as PaystubActions from '../actions/paystub.actions';
import { Payroll, PayrollTrip, PayComponent, PayableHour, EmployeePayableHour, GeneratePayrollOptions, ClientPayComponents } from '../models/payroll.model';
import { PayrollFlow, getPayrollFlow } from '../util/payroll-flow-util'
import { PayrateConfigs } from '../models/employee-payment-profile.model';
import { PayrollProcessorEmployee, PayrollProcessorEmployeeState } from '../models/paychex.model';
import { stat } from 'fs';

export interface PaymentState {
    payrolls: Payroll[]; // List of all payrolls when you search
    generatePayrollOptions?: GeneratePayrollOptions;
    selectedPayroll?: Payroll; // Payroll that you are processing
    paystubComponents: ClientPayComponents; // Paystub components that all clients have 
    payrateConfigs?: PayrateConfigs; // Payrate configs that this client has. TODO: Make this client specific as today it works only if the all clients under one organization are the same
    payableTrips?: PayrollTrip[];
    payableHours?: EmployeePayableHour[];
    payrollProcessorEmployees?: PayrollProcessorEmployeeState; //This saves list of employees for each client
    payrollFlow?: PayrollFlow // Has Payroll flow configs.(Steps of the payroll flow, active step etc)
}

export const initialState: PaymentState = {
    payrolls: [],
    paystubComponents: {}
};


export const paymentReducer = createReducer(
    initialState,

    //Payroll
    on(PayrollActions.loadPayrollsSuccess, (state, { payrolls }) => ({ ...state, payrolls })),
    on(PayrollActions.loadPayrollByIDSuccess, (state, { payroll }) => ({ ...state, selectedPayroll: payroll })),
    on(PayrollActions.clearSelectedPayroll, (state, { }) => ({ ...state, selectedPayroll: undefined, payrollFlow: undefined, payableTrips: undefined, payableHours: undefined })),
    on(PayrollActions.getGeneratePayrollOptionsSuccess, (state, { generatePayrollOptions }) => ({ ...state, generatePayrollOptions })),
    on(PayrollActions.generatePayrollSuccess, (state, { payroll }) => ({ ...state, payrolls: [payroll, ...state.payrolls] })),
    on(PayrollActions.generatePaystubsSuccess, (state, { payroll }) => ({
        ...state,
        payrolls: state.payrolls.map(pr => pr.id === payroll.id ? payroll : pr),
        selectedPayroll: { ...state.selectedPayroll, ...payroll } // override new properties
    })),

    //Payable Hours
    on(PayableHourActions.loadPayableHoursSuccess, (state, { payableHours }) => ({ ...state, payableHours })),

    //Payable Trips
    on(PayableTripsActions.loadPayableTripsSuccess, (state, { payableTrips }) => ({ ...state, payableTrips })),
    on(PayrollActions.enablePayableTripsEditSuccess, (state, { payroll }) => ({
        ...state,
        payrolls: state.payrolls.map(pr => pr.id === payroll.id ? payroll : pr),
        selectedPayroll: { ...state.selectedPayroll, ...payroll } // override new properties
    })),
    on(PayableTripsActions.confirmPayableTripsSuccess, (state, { payroll }) => ({
        ...state,
        payrolls: state.payrolls.map(pr => pr.id === payroll.id ? payroll : pr),
        selectedPayroll: { ...state.selectedPayroll, ...payroll } // override new properties
    })),
    on(PayableTripsActions.clearLoadedPayableTripsAndHours, (state, { }) => ({ ...state, payableTrips: undefined, payableHours: undefined })),

    //EmployeePaymentProfile
    on(EmployeePaymentProfileActions.loadPayrateConfigurationsSuccess, (state, { payrateConfigs }) => ({ ...state, payrateConfigs })),
    on(EmployeePaymentProfileActions.loadPaychexWorkersSuccess, (state, { clientId, paychexWorkers }) => {
        const currentPayrollProcessorEmployees = { ...state.payrollProcessorEmployees ?? {} }
        currentPayrollProcessorEmployees[clientId] = paychexWorkers

        return { ...state, payrollProcessorEmployees: currentPayrollProcessorEmployees }
    }),


    //Paystubs
    on(PaystubActions.loadPayComponentsSuccess, (state, { clientId, payrollType, paystubComponents }) => {
        const key = `${clientId}_${payrollType}`
        return { ...state, paystubComponents: { ...state.paystubComponents, [key]: paystubComponents } }
    }),

    on(PayrollActions.savePaystubAmountsSuccess, (state, { payroll }) => ({
        ...state,
        payrolls: state.payrolls.map(pr => pr.id === payroll.id ? payroll : pr),
        selectedPayroll: { ...state.selectedPayroll, ...payroll } // override new properties
    })),
    on(PayrollActions.saveRegularPaymentsSuccess, (state, { payroll }) => ({
        ...state,
        payrolls: state.payrolls.map(pr => pr.id === payroll.id ? payroll : pr),
        selectedPayroll: { ...state.selectedPayroll, ...payroll } // override new properties
    })),

    //Payroll flow
    on(PayrollActions.payrollFlowNext, (state, { }) => {
        const currentActiveStep = state.payrollFlow?.activeStepIndex ?? 0
        if (state.payrollFlow && currentActiveStep + 1 < state.payrollFlow?.steps.length) {
            return {
                ...state,
                payrollFlow: { ...state.payrollFlow, activeStepIndex: currentActiveStep + 1 }
            }
        }
        //no state change if not match
        return state;
    }),
    on(PayrollActions.payrollFlowPreviuos, (state, { }) => {
        //If current index exists and is not the first element
        const currentActiveStep = state.payrollFlow?.activeStepIndex ?? 0
        if (state.payrollFlow && currentActiveStep > 0) {
            return {
                ...state,
                payrollFlow: { ...state.payrollFlow, activeStepIndex: currentActiveStep - 1 }
            }
        }
        //no state change if not match
        return state;
    }),
    on(PayrollActions.payrollFlowSetActiveStep, (state, { stepId }) => {
        let payrollFlow = state.payrollFlow;
        if (!payrollFlow) {
            const frequency = state.selectedPayroll?.frequency
            const payrollType = state.selectedPayroll?.payrollType
            payrollFlow = getPayrollFlow(payrollType, frequency)
        }
        const stepIndex = payrollFlow.steps.findIndex(s => s.id === stepId);
        if (stepIndex > -1) {
            return {
                ...state,
                payrollFlow: { ...payrollFlow, activeStepIndex: stepIndex }
            }
        }
        //no state change if not match
        return state;
    }),
);
