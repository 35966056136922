import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest, map } from 'rxjs';
import { Employee, EmployeePaymentProfile } from '../models/main.models';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
  private resourceUrl: string = environment.apiEndpoint;

  constructor(private http: HttpClient) { }

  getEmployees({ organizationId, showDeletedEmployees }: { organizationId: string; showDeletedEmployees?: boolean; employeeName?: string; }): Observable<Employee[]> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/employees`
    if (showDeletedEmployees) {
      url += "?showDeleted=true";
    }
    return this.http.get<Employee[]>(url);
  }

  getEmployeesFullprofile(organizationId: string): Observable<Employee[]> {
    const employees$ = this.getEmployees({ organizationId });
    const employeePaymentProfile$ = this.getEmployePaymentProfiles(organizationId);

    const combined$ = combineLatest([employees$, employeePaymentProfile$]).pipe(
      map(([employees, employeeProfiles]) => {
        // Merge the arrays based on the common field "employeeId"
        const mergedEmployeeArray: Employee[] = employees.map(employee => {
          const paymentProfile = employeeProfiles.find(payProfile => payProfile.employeeId === employee.id);
          return {
            ...employee,
            paymentProfile
          };
        });
        return mergedEmployeeArray;
      }),
    );
    return combined$;
  }

  getEmployee(organizationId: string, employeeId: number): Observable<Employee> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/employees/${employeeId}`
    return this.http.get<Employee>(url);
  }

  getEmployeeFullprofile(organizationId: string, employeeId: number): Observable<Employee> {
    const employee$ = this.getEmployee(organizationId, employeeId);
    const employeePaymentProfile$ = this.getEmployeePaymentProfile(organizationId, employeeId);

    const combined$ = combineLatest([employee$, employeePaymentProfile$]).pipe(
      map(([employee, paymentProfile]) => {
        if (paymentProfile) {
          employee.paymentProfile = paymentProfile;
        }
        return employee;
      }),
    );
    return combined$;
  }

  updateEmployee(organizationId: string, employee: Employee): Observable<Employee> {
    const url = `${this.resourceUrl}/organizations/${organizationId}/employees/${employee.id}`;
    return this.http.put<Employee>(url, employee);
  }

  upsertEmployeePaymentProfile(organizationId:string, employeePaymentProfile: EmployeePaymentProfile): Observable<EmployeePaymentProfile> {
    const url = `${this.resourceUrl}/organizations/${organizationId}/payment/payment-profiles/${employeePaymentProfile.employeeId}`;
    return this.http.post<EmployeePaymentProfile>(url, employeePaymentProfile);
  }



  //   deleteEmployee(id: number): Observable<void> {
  //     const url = `${this.apiUrl}/${id}`;
  //     return this.http.delete<void>(url);
  //   }

  private getEmployePaymentProfiles(organizationId: string): Observable<EmployeePaymentProfile[]> {
    const url = `${this.resourceUrl}/organizations/${organizationId}/payment/payment-profiles`
    return this.http.get<EmployeePaymentProfile[]>(url);
  }
  private getEmployeePaymentProfile(organizationId: string, employeeId: number): Observable<EmployeePaymentProfile> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/payment/payment-profiles/${employeeId}`
    return this.http.get<EmployeePaymentProfile>(url);
  }
}
