import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientFeatureFlags, FeatureFlags } from '../models/model'
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {

    private resourceUrl: string = environment.apiEndpoint;

    constructor(private http: HttpClient) {
    }

    getFeatureFlags(organizationId: string, clientId?: string): Observable<ClientFeatureFlags[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId??'*'}/feature-flags`;
        return this.http.get<ClientFeatureFlags[]>(url);
    }


}
