import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as PayableTripActions from '../actions/payable-trips.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { PaymentsService } from '../services/payroll.service';
import { Payroll, PayrollTrip } from '../models/payroll.model';

@Injectable()
export class PayableTripEffects {
    constructor(private actions$: Actions, private paymentService: PaymentsService, private store: Store<AppState>) { }

   
    loadPayableTrips$ = createEffect(() => this.actions$.pipe(
        ofType(PayableTripActions.loadPayableTrips),
        mergeMap(({ organizationId, clientId, payrollId, weekNumber, isApproved }) => this.paymentService.getPayableTrips(organizationId, clientId, payrollId, weekNumber, isApproved).pipe(
            map((payableTrips: PayrollTrip[]) => {
                return PayableTripActions.loadPayableTripsSuccess({ payableTrips });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );

    confirmPayableTrips$ = createEffect(() => this.actions$.pipe(
        ofType(PayableTripActions.confirmPayableTrips),
        mergeMap(({ organizationId, clientId, payrollId }) => this.paymentService.confirmPayableTrips(organizationId, clientId, payrollId).pipe(
            map((payroll: Payroll) => {
                console.log('confirm payable trips effect triggered')
                return PayableTripActions.confirmPayableTripsSuccess({ payroll });
            }),
            catchError(error => of( /* handle error action */))
        )
        )
    )
    );
}
