import { createReducer, on } from '@ngrx/store';
import * as UIActions from '../actions/ui.actions';
import * as FeatureFlagsActions from '../actions/feature-flags.actions';

import { ClientFeatureFlags, NotificationMessage } from '../models/model';

export interface UIState {
    loadingActions: string[];
    notification?: NotificationMessage;
    featureFlags: ClientFeatureFlags[]
}

export const initialState: UIState = {
    loadingActions: [],
    featureFlags: []
};

export const uiReducer = createReducer(
    initialState,
    //Start & end loading components
    on(UIActions.startLoading, (state, { action }) => ({ ...state, loadingActions: [...state.loadingActions, action] })),
    on(UIActions.endLoading, (state, { action }) => ({
        ...state,
        loadingActions: state.loadingActions.filter(a => a !== action)
    })),
    on(UIActions.addNotificationMessage, (state, { message, messageType }) => {
        const notification = new NotificationMessage()
        notification.message = message;
        notification.messageType = messageType
        return { ...state, notification }
    }),

    // Feature flags
    on(FeatureFlagsActions.loadFeatureFlagsSuccess, (state, { clientFeatureFlags }) => ({ ...state, featureFlags: clientFeatureFlags }))
);
