<!--<c-footer>-->
<div>
  <a href="https://www.smarter-trucking.com" target="_blank">Smarter Trucking</a>

  {{envName!='prod'?envName:''}}
  <span> &copy; 2023 Orangwall LLC</span>
</div>
<!-- <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/angular" target="_blank">
      <span> CoreUI v4 for Angular</span>
    </a>
  </div> -->
<!--</c-footer>-->