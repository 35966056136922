import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Vehicle } from '../models/main.models';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class VehicleService {
  private resourceUrl: string = environment.apiEndpoint;

  constructor(private http: HttpClient) { }

  getVehicles(organizationId: string, clientId: string): Observable<Vehicle[]> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/vehicles`
    return this.http.get<Vehicle[]>(url);
  }

  getVehicleByNumber(organizationId: string, clientId: string, vehicleNumber: string): Observable<Vehicle> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/vehicles/${vehicleNumber}`
    return this.http.get<Vehicle>(url);
  }

  updatedVehicle(organizationId: string, clientId: string, vehicleNumber: string, vehicleData: Vehicle): Observable<Vehicle> {
    let url = `${this.resourceUrl}/organizations/${organizationId}/clients/${clientId}/vehicles/${vehicleNumber}`
    return this.http.put<Vehicle>(url, vehicleData);
  }

}
