import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AddHeaderInterceptor } from './authentication/auth-interceptor';
import { AuthGuard } from './authentication/auth.guard';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { EmployeeService } from './store/main/services/employee.service';

// import { EmployeeEffects } from './store/main/effects/employee.effects';
// import { ClientEffects } from './store/main/effects/client.effects';
//Store Effects 
import { mainEffects } from './store/main/effects'
import { paymentEffects } from './store/payment/effects'
import { uiEffects } from './store/ui/effects'
import { scheduleEffects } from './store/schedule/effects'

//Store Redusers
import { schedulingReducer } from './store/schedule/reducers/schedule.reducer';
import { paymentReducer } from './store/payment/reducers/payroll.reducer';
import { mainReducer } from './store/main/reducers/main.reducer';
import {uiReducer} from './store/ui/reducers/ui.reducer'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';


// Import routing module
import { AppRoutingModule } from './app-routing.module';

// Import app component
import { AppComponent } from './app.component';

// Import containers
import {
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
  SecureAsideComponent,
  SecureFooterComponent,
  SecureHeaderComponent,
  SecureLayoutComponent
} from './containers';

import {
  BadgeModule,
  BreadcrumbModule,
  ButtonModule,
  FooterModule,
  GridModule,
  HeaderModule,
  NavModule,
  SidebarModule,
  AvatarModule,
  ToastModule,
  // ButtonGroupModule,
  // CardModule,
  DropdownModule,
  MultiSelectModule,
  // FormModule,
  // ListGroupModule,
  // ProgressModule,
  // SharedModule,
  // TabsModule,
  // UtilitiesModule
} from '@coreui/angular-pro';

import { IconModule, IconSetService } from '@coreui/icons-angular';
// import { OwProgressStepperComponent } from './views/components/ow-progress-stepper/ow-progress-stepper.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AutoFocusDirective } from './views/components/auto-focus-directive/auto-focus.directive';

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent,
  SecureLayoutComponent,
  SecureAsideComponent,
  SecureFooterComponent,
  SecureHeaderComponent
];

@NgModule({
  declarations: [
    AppComponent,
    ...APP_CONTAINERS
  ],
  imports: [
    FormsModule,
    StoreModule.forRoot({ui: uiReducer, main: mainReducer, schedule: schedulingReducer, payment: paymentReducer }),
    EffectsModule.forRoot([...mainEffects, ...scheduleEffects, ...paymentEffects, ...uiEffects]),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BreadcrumbModule,
    FooterModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    SidebarModule,
    BadgeModule,
    AvatarModule,
    ToastModule,
    // ButtonGroupModule,
    // CardModule,
    DropdownModule,
    MultiSelectModule,
    // FormModule,
    // ListGroupModule,
    // ProgressModule,
    // SharedModule,
    // TabsModule,
    // UtilitiesModule,
    /* configure App with AmplifyAuthenticatorModule */
    AmplifyAuthenticatorModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    },
    provideCharts(withDefaultRegisterables()),
    IconSetService,
    Title
  ],
  bootstrap: [AppComponent, DashboardComponent]
})
export class AppModule {
}