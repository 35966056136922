import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{

  constructor(private _authService:AuthService) {}

  ngOnInit() {
  }

}
