import { createReducer, on } from '@ngrx/store';
import { RunSegment, ScheduledRun, WeeklySchedule } from '../models/schedule.model';
import * as WeeklyScheduleActions from '../actions/weekly-schedule.actions';
import * as ScheduledRunActions from '../actions/scheduled-run.actions';
import * as RunSegmentActions from '../actions/run-segment.actions';

export interface ScheduleState {
    weeklySchedules: WeeklySchedule[];
    selectedWeeklySchedule?: WeeklySchedule;
}

export const initialState: ScheduleState = {
    weeklySchedules: [],
};

export const schedulingReducer = createReducer(
    initialState,

    //Weekly Schedule
    on(WeeklyScheduleActions.loadWeeklySchedulesSuccess, (state, { weeklySchedules }) => ({ ...state, weeklySchedules })),
    on(WeeklyScheduleActions.generateNextWeeklyScheduleSuccess, (state, { weeklySchedule }) => ({ ...state, weeklySchedules: [weeklySchedule, ...state.weeklySchedules] })),

    //Selected Weekly Schedule
    on(WeeklyScheduleActions.loadSelectedWeeklyScheduleSuccess, (state, { weeklySchedule }) => ({ ...state, selectedWeeklySchedule: weeklySchedule })),
    on(WeeklyScheduleActions.clearSelectedWeeklySchedule, (state, { }) => ({ ...state, selectedWeeklySchedule: undefined })),
    //Update the returned scheduledRun in the selectedWeeklySchedule
    on(WeeklyScheduleActions.sendRunSegmentsNotificationsSuccess, (state, { runSegments }) => {
        const selectedWeeklySchedule: WeeklySchedule | undefined = state.selectedWeeklySchedule;
        if (selectedWeeklySchedule) {
            //create a map with key scheduleId & value the list of segments that got notification sent
            const runSegmentMap: any = {}
            runSegments.forEach((rs: any) => {
                if (!runSegmentMap.hasOwnProperty(rs.scheduledRunId)) {
                    runSegmentMap[rs.scheduledRunId] = []
                }
                runSegmentMap[rs.scheduledRunId].push(rs)
            })
            console.log(runSegmentMap)

            //populate the scheudled run list by replacing all RunSegments that were updated
            const updatedScheduledRuns: ScheduledRun[] = []
            //loop through all scheduled runs and update the ones that had updated RunSegments
            selectedWeeklySchedule.scheduledRuns.forEach(run => {
                const srId = `${run.id}`
                if (Object.keys(runSegmentMap).includes(srId)) {
                    console.log(`processing ${srId}`)
                    if (run.runSegments) {
                        //populate ther run segments by replacing the updated ones from original run segments in state
                        console.log(run.runSegments)
                        const runSegments = run.runSegments.map(rs => {
                            const updatedRunSegments: RunSegment[] = runSegmentMap[srId]
                            const updatedRs = updatedRunSegments.find(updatedRs => updatedRs.id === rs.id)
                            return updatedRs ?? rs;
                        })
                        console.log(runSegments)
                        const updatedSr = { ...run, runSegments: runSegments }
                        console.log(updatedSr)
                        updatedScheduledRuns.push(updatedSr)
                    }
                } else {
                    updatedScheduledRuns.push(run)
                }
                console.log(updatedScheduledRuns)
            })
            const newState = { ...state, selectedWeeklySchedule: { ...selectedWeeklySchedule, scheduledRuns: updatedScheduledRuns } }
            return newState
        }
        return state
    }),


    //Add the returned scheduledRun in the selectedWeeklySchedule
    on(ScheduledRunActions.createScheduledRunSuccess, (state, { scheduledRun }) => {
        const newState = { ...state };
        const selectedWeeklySchedule: WeeklySchedule | undefined = state.selectedWeeklySchedule;
        if (selectedWeeklySchedule) {
            newState.selectedWeeklySchedule = { ...selectedWeeklySchedule, scheduledRuns: [...selectedWeeklySchedule.scheduledRuns, scheduledRun] };
        }
        return newState
    }),

    //Add the returned scheduledRun in the selectedWeeklySchedule
    on(RunSegmentActions.createRunSegmentSuccess, (state, { runSegment }) => {
        const newState = { ...state };
        const selectedWeeklySchedule: WeeklySchedule | undefined = state.selectedWeeklySchedule;
        if (selectedWeeklySchedule) {
            // console.log(runSegment)
            //Add new segment
            const scheduledRunToUpdate = selectedWeeklySchedule.scheduledRuns.find(sr => sr.id === runSegment.scheduledRunId)
            if (scheduledRunToUpdate) {
                const updatedScheduledRun: ScheduledRun = { ...scheduledRunToUpdate, runSegments: [...(scheduledRunToUpdate.runSegments ?? []), runSegment] }
                newState.selectedWeeklySchedule = { ...selectedWeeklySchedule, scheduledRuns: [...selectedWeeklySchedule.scheduledRuns.map(sr => sr.id === runSegment.scheduledRunId ? updatedScheduledRun : sr)] };
            }
        }
        return newState
    }),
    //Update the returned scheduledRun in the selectedWeeklySchedule
    on(RunSegmentActions.updateRunSegmentSuccess, (state, { runSegment }) => {
        const newState = { ...state };
        const selectedWeeklySchedule: WeeklySchedule | undefined = state.selectedWeeklySchedule;
        if (selectedWeeklySchedule) {
            //replace updated segment
            const scheduledRunToUpdate = selectedWeeklySchedule.scheduledRuns.find(sr => sr.id === runSegment.scheduledRunId)
            if (scheduledRunToUpdate) {
                const updatedScheduledRun: ScheduledRun = { ...scheduledRunToUpdate, runSegments: [...(scheduledRunToUpdate.runSegments ?? []).map((rs: RunSegment) => rs.id === runSegment.id ? runSegment : rs)] }
                newState.selectedWeeklySchedule = { ...selectedWeeklySchedule, scheduledRuns: [...selectedWeeklySchedule.scheduledRuns.map(sr => sr.id === runSegment.scheduledRunId ? updatedScheduledRun : sr)] };
            }
        }
        return newState
    }),

    //Update the returned scheduledRun in the selectedWeeklySchedule
    on(RunSegmentActions.deleteRunSegmentSuccess, (state, { scheduledRunId, id }) => {
        const newState = { ...state };
        const selectedWeeklySchedule: WeeklySchedule | undefined = state.selectedWeeklySchedule;
        if (selectedWeeklySchedule) {
            //remove deleted segment
            const scheduledRunToUpdate = selectedWeeklySchedule.scheduledRuns.find(sr => sr.id === scheduledRunId)
            if (scheduledRunToUpdate?.runSegments) {
                const updatedScheduledRun: ScheduledRun = { ...scheduledRunToUpdate, runSegments: [...scheduledRunToUpdate.runSegments.filter((rs: RunSegment) => rs.id !== id)] }
                newState.selectedWeeklySchedule = { ...selectedWeeklySchedule, scheduledRuns: [...selectedWeeklySchedule.scheduledRuns.map(sr => sr.id === scheduledRunId ? updatedScheduledRun : sr)] };
            }
        }
        return newState
    }),

);
