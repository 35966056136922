import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout/default-layout';
import { SecureLayoutComponent } from './layout/secure-layout';
import {EmptyLayoutComponent} from './layout/empty-layout/empty-layout.component'

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./views/website/routes').then((m) => m.routes)
  },
  {
    path: 'auth',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./views/public/auth/routes').then((m) => m.routes)
  },
  {
    path: 'secure',
    component: SecureLayoutComponent,
    loadChildren: () => import('./views/secure/routes').then((m) => m.routes)
  },
  {
      path: 's',
      component: EmptyLayoutComponent,
      loadChildren: () => import('./views/secure/routes.sms').then((m) => m.routes)
  },
  { path: '**', redirectTo: 'home' }
    ];

