import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { VehicleService } from '../services/vehicle.service';
import * as VehicleActions from '../actions/vehicle.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable()
export class VehicleEffects {
    constructor(private actions$: Actions, private vehicleService: VehicleService, private store: Store<AppState>) { }

    loadVehicles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(VehicleActions.loadVehicles),
            mergeMap(({ organizationId, clientId }) =>

                this.vehicleService.getVehicles(organizationId, clientId ).pipe(
                    map(vehicles => VehicleActions.loadVehiclesSuccess({ vehicles })),
                    catchError(error => of(/* handle error action */))
                )
            )
        )
    );
}
