import { createAction, props } from '@ngrx/store';
import { Payroll, PayrollTrip } from '../models/payroll.model';

// Load Payable Trips
export const loadPayableTrips = createAction('[Payment->PayalbeTrips] Load Payable Trips', props<{ organizationId: string, clientId: string, payrollId: number, weekNumber: number, isApproved?: boolean }>());
export const loadPayableTripsSuccess = createAction('[Payment->PayalbeTrips] Load Payable Trips Success', props<{ payableTrips: PayrollTrip[] }>());
export const clearLoadedPayableTripsAndHours = createAction('[Payment->PaybleTrips] Clear Loaded Payable Trips and Hours');


// // Confirm Payable Trips
export const confirmPayableTrips = createAction('[Payment->Payrolls] Confirm Payable Trips', props<{ organizationId: string, clientId: string, payrollId: number }>());
export const confirmPayableTripsSuccess = createAction('[Payment->Payrolls] Confirm Payable Trips Success', props<{ payroll: Payroll }>()); 
