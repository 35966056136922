import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HeaderComponent, ToasterComponent, ToasterPlacement } from '@coreui/angular-pro';

import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { MessageType, NotificationMessage } from 'src/app/store/ui/models/model';
import { ToastAlertComponent } from 'src/app/views/components/ow-toast-alert/ow-toast-alert.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { AuthService } from 'src/app/authentication/authentication.service';
import * as FeatureFlagActions from 'src/app/store/ui/actions/feature-flags.actions'

@Component({
  selector: 'app-secure-header',
  templateUrl: './secure-header.component.html'
})
export class SecureHeaderComponent extends HeaderComponent implements OnInit {
  @ViewChild(ToasterComponent) toaster!: ToasterComponent;

  userRoles!:string[];
  constructor(private router: Router, private authService: AuthService, private amplifyService: AuthenticatorService, private store: Store<AppState>) {
    super();
  }

  async ngOnInit() {
    const currentUser = await this.authService.getCurrentUser();
    this.userRoles = currentUser?.roles?? []
    
    // Subscribe to notifications for whole application
    const notification$ = this.store.select(state => state.ui.notification);
    notification$.subscribe(
      {
        next: (notificationMessage) => {
          if (notificationMessage) {
            this.addToast(notificationMessage)
          }
        },
        error: err => {
          console.log(err);
          const notification = new NotificationMessage()
          notification.message = 'Something went wrong! Please try again!'
          notification.messageType = MessageType.Error;
          this.addToast(notification);
        }
      }
    )

    // Start loading feature flags
    const organizationId = await this.authService.getOrganizationId();
    this.store.dispatch(FeatureFlagActions.loadFeatureFlags({ organizationId }))
  }

  addToast(notification: NotificationMessage) {
    const options = {
      // title: `CoreUI for Angular Toast`,
      message: notification.message,
      delay: 8000,
      placement: ToasterPlacement.TopEnd,
      color: notification.messageType,
      autohide: true,
    }
    const componentRef = this.toaster.addToast(ToastAlertComponent, { ...options });
  }


  logOut() {
    this.amplifyService.signOut()
    this.router.navigate(['/']);
  }

  @Input() sidebarId: string = 'sidebar1';
}


