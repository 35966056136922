import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { SchedulingService } from 'src/app/store/schedule/services/schedule.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trip-driver-confirmed',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './trip-driver-confirmed.component.html',
  styleUrl: './trip-driver-confirmed.component.scss'
})
export class TripDriverConfirmedComponent implements OnInit, OnDestroy {

  tripConfirmationDetails!:any;

  destroy$ = new Subject<boolean>();

  constructor(
    private schedulingService: SchedulingService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    const tripId = this.activatedRoute.snapshot.paramMap.get('tripId');
    const driverId = this.activatedRoute.snapshot.paramMap.get('driverId');
    if (tripId && driverId) {
      this.schedulingService.confirmRunSegmentNotification(tripId, driverId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          {
            next: (tripConfirm) => {
              this.tripConfirmationDetails = tripConfirm
            },
            error: err => {
              console.log(err)
            }
          }
        )
    }
  }


  ngOnDestroy(): void {
    // Unsubscribe
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
