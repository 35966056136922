import {
  cilApplicationsSettings,
  cilMenu,
  cilSpeedometer,
  cilCalendar,
  cilMoney,
  cilGraph,
  cilNotes,
  cilSettings,
  cilUser,
  cilEnvelopeOpen,
  cilLockLocked,
  cilBell
} from '@coreui/icons';

export const iconSubset = {
  cilApplicationsSettings,
  cilMenu,
  cilSpeedometer,
  cilCalendar,
  cilMoney,
  cilGraph,
  cilNotes,
  cilSettings ,
  cilUser,
  cilEnvelopeOpen,
  cilLockLocked,
  cilBell
};

export enum IconSubset {
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilMenu = 'cilMenu',
  cilSpeedometer = 'cilSpeedometer',
  cilCalendar = 'cilCalendar',
  cilMoney = 'cil-money',
  cilGraph = 'cil-graph',
  cilNotes = 'cilNotes',
  cilSettings  = 'cilSettings',
  cilUser = 'cilUser',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilLockLocked = 'cilLockLocked',
  cilBell = 'cilBell'
}
